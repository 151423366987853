<template>
  <!-- 部门管理 -->
  <div class="container">
    <div class="head">
      <div class="dataOper">
        <div class="OperButton">
          <div class="addBtn" @click="dialogFormVisible = true">
            <img src="../../../assets/images/add.png" style="margin-right: 5px;width: 13px;">添加
          </div>
        </div>
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="body">
      <div class="dataTable">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;font-family: PingFangRoutine;"
          :header-cell-style="tableHeaderColor"
          :cell-style="{ color: '#2C2C2C', fontSize: '14px' }"
        >
          <el-table-column
            prop="name"
            label="部门名称"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="remark"  label="部门描述" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="添加时间" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template #default="scope">
              <div class="operating">
                <div class="edit" @click="departmentedit(scope.row.id)">
                  <img src="../../../assets/images/edit.png"  style="width: 18px;height: 18px;">
                </div>
                <div class="delete" @click="departmentdel(scope.row.id)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 添加 -->
      <div class="dialog">
      <el-dialog
        center
        custom-class="dialog"
        title="添加部门"
        :visible.sync="dialogFormVisible"
        :modal-append-to-body="false"
      >
        <el-form
          :model="ruleForm"
          label-width="100px"
          label-position="right"
          :rules="rules"
          ref="inserform"
        >
          <el-form-item label="上级部门：" prop="parentId">
            <el-select
              v-model="ruleForm.parentId"
              placeholder="请选择上级部门"
            >
              <el-option
                label="北京新发地掌鲜网络科技服务有限公司"
                value="0"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="部门名称：" prop="name">
            <el-input  v-model.trim="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item style="margin-top: 25px;" label="部门描述：" prop="remark">
            <div class="description">
                 <el-input  type="input" class="ms" v-model="ruleForm.remark" ></el-input>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addment('inserform')"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      </div>
      <!-- 添加 -->
      <!-- 编辑 -->
      <el-dialog
        :append-to-body="true"
        title="编辑部门"
        :visible.sync="dialogFormVisibleedit"
        width="35%"
      >
        <div style="margin: 20px"></div>
        <el-form
          :model="editForm"
          label-width="100px"
          label-position="right"
          :rules="rules"
          ref="inserform"
        >
          <el-form-item label="上级部门：" prop="parentId">
            <el-select v-model="editForm.parentId" placeholder="请选择上级部门">
              <el-option
                label="北京新发地掌鲜网络科技服务有限公司"
                value="0"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="部门名称：" prop="name">
            <el-input
              v-model.trim="editForm.name"
              disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item label="部门描述：" prop="remamsrk">
              <el-input type="textarea" class="ms" v-model="editForm.remark"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleedit = false">取 消</el-button>
          <el-button type="primary" @click="editadd('inserform')"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <!-- 编辑 -->
    </div>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
  </div>
</template>
<script>
import {
  departmentList,
  departmentAdd,
  departmentDetail,
  departmentEdit,
  departmentDelete,
} from "@/api/department";
import "../../../font-style/font.css";
export default {
  data() {
    return {
      currentPage1: 5,
      tableData: [],
      queryinfo: { currPage: "" },
      total: null,
      dialogFormVisible: false,
      dialogFormVisibleedit: false,
      rules: {
        parentId: [
          { required: true, message: "请选择上级部门", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入部门名称", trigger: "blur" }],
        remark: [],
      },
      ruleForm: {
        name: "",
        parentId: "",
        remark: "",
      },
      editForm: {},
    };
  },
  activated() {
    this.list();
  },
  methods: {
    //部门列表
    list() {
      departmentList(this.queryinfo).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
      });
    },
    //分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },
    // table
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
      }
    },
    // 新建部门
    addment(inserform) {
      let self = this;
      self.$refs[inserform].validate((res) => {
        if (res) {
          departmentAdd(this.ruleForm).then((res) => {
            this.list();
          });
          self.dialogFormVisible = false;
          self.ruleForm.name = null;
          self.ruleForm.parentId = null;
          self.ruleForm.remark = null;
        }
      });
    },
    //获取部门详情
    departmentedit(id) {
      this.dialogFormVisibleedit = true;
      departmentDetail({ id }).then((res) => {
        this.editForm = res.data;
      });
    },
    //编辑部门
    editadd(inserform) {
      let self = this;
      self.$refs[inserform].validate((res) => {
        if (res) {
          departmentEdit(this.editForm).then(() => {
            this.list();
          });
          self.dialogFormVisibleedit = false;
        }
      });
    },
    //删除部门
    async departmentdel(id) {
      if (!(await this._box())) return;
      departmentDelete({ id: id }).then((res) => {
        let totalPage = Math.ceil((this.total - 1) / 10);
        let currentPage =
          this.queryinfo.currPage > totalPage
            ? totalPage
            : this.queryinfo.currPage;
        this.queryinfo.currPage = currentPage < 1 ? 1 : currentPage;
        this.list();
      });
    },
  },
};
</script>
<style  lang="less" scoped>
@import "../../../assets/css/common.less";
     /deep/ .ms>.el-input__inner{
        height: 110px !important;
      }
      ::v-deep .el-dialog{
             display: flex;
             flex-direction: column;
             margin:0 !important;
             position:absolute;
             top:50%;
             left:50%;
             transform:translate(-50%,-50%);
             max-height:calc(100% - 30px);
             max-width:calc(100% - 30px);
         }
        ::v-deep  .el-dialog .el-dialog__body{
             flex:1;
         }
         /deep/ .head .el-card__body{
           padding-left: 0px;
         }
         /deep/.dialog-footer{
           margin-bottom: 30px;
         }
         /deep/ .el-button--primary{
           margin-left: 30px;
         }
         /deep/ .el-dialog--center .el-dialog__body{
           padding: 25px 50px 0px 44px;
         }
         /deep/ .el-dialog__header {
          padding: 35px 20px 15px;
         }
         .head{
           padding-top: 20px;
           padding-bottom: 20px;
           box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
         }
</style>
<style lang="less">
 .dialog{
   border-radius: 10px;
  width: 690px;
  height: 450px;
 }
</style>
